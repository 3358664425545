<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label for="date_from">Date From</label>
            <b-input
              id="date_from"
              v-model="filter.date_from"
              type="date"
              @change="onFilterChanged"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label for="date_from">Date To</label>
            <b-input
              id="date_from"
              v-model="filter.date_to"
              type="date"
              @change="onFilterChanged"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <div class="form-group">
            <label>Filter By Status</label>
            <b-select
              v-model="filter.filter_status"
              :options="options.filter_statuses"
              @change="onFilterChanged"
            />
          </div>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Search String</label>
            <b-input
              v-model="tableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
        >
          <b-form-checkbox
            id="opportunity_only"
            v-model="filter.opportunity_only"
            name="opportunity_only"
            value="1"
            unchecked-value="0"
            switch
            @change="onFilterChanged"
          >
            <strong>Show Sales Opportunity Only?</strong>
          </b-form-checkbox>
        </b-col>
      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :per-page="tableSettings.perPage"
        :current-page="tableSettings.currentPage"
        :items="myProvider"
        :fields="tableSettings.fields"
        :sort-by.sync="tableSettings.sortBy"
        :sort-desc.sync="tableSettings.sortDesc"
        :sort-direction="tableSettings.sortDirection"
        :filter="tableSettings.filter"
        :filter-included-fields="tableSettings.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{ tableSettings.currentPage * tableSettings.perPage - tableSettings.perPage + (data.index + 1) }}
        </template>

        <template #cell(opportunity)="data">
          {{ data.item.sales_opportunity == 1 ? "Yes" : "No" }}
        </template>

        <template #cell(booked)="data">
          {{ data.item.successful_booking == 1 ? "Yes" : "No" }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(actions)="row">
          <div class="text-nowrap text-center">
            <b-button
              size="sm"
              class="mr-1"
              @click="onShowDetailForm(row.item)"
            >
              View
            </b-button>
          </div>
        </template>

      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSettings.perPage"
              :options="tableSettings.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSettings.currentPage"
            :total-rows="tableSettings.totalRows"
            :per-page="tableSettings.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-view-detail"
      title="View Details"
      scrollable
      no-close-on-backdrop
      size="lg"
    >
      <b-tabs>
        <b-tab
          title="Request Details"
        >
          <b-row>
            <b-col
              cols="12"
            >
              <b-form-group>
                <label>Request Date</label>
                <b-input
                  :value="dateTimeFormatter(selected.visit_request?.created_at)"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group>
                <label>Ticket Number</label>
                <b-input
                  :value="selected.visit_request?.ticket_number"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group>
                <label>Ticket Category</label>
                <b-input
                  :value="selected.visit_request?.ticket_category_name"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group>
                <label>Due Date</label>
                <b-input
                  :value="selected.visit_request?.due_date"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group>
                <label>Customer Name</label>
                <b-input
                  :value="`${selected.visit_request?.customer?.first_name} ${selected.visit_request?.customer?.last_name}`"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group>
                <label>Mobile Number</label>
                <b-input
                  :value="selected.visit_request?.customer?.mobile_number"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group>
                <label>Status</label>
                <b-input
                  :value="selected.visit_request?.status"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group>
                <label>Remarks</label>
                <b-textarea
                  :value="selected.visit_request?.remarks"
                  rows="10"
                  max-rows="10"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab
          title="Visitation Logs"
        >
          <b-table
            ref="visitationLogTable"
            hover
            responsive
            class="mt-2"
            :per-page="tableVisitationLogSettings.perPage"
            :current-page="tableVisitationLogSettings.currentPage"
            :items="myVisitationLogProvider"
            :fields="tableVisitationLogSettings.fields"
            :sort-by.sync="tableVisitationLogSettings.sortBy"
            :sort-desc.sync="tableVisitationLogSettings.sortDesc"
            :sort-direction="tableVisitationLogSettings.sortDirection"
            :filter="tableVisitationLogSettings.filter"
            :filter-included-fields="tableVisitationLogSettings.filterOn"
            show-empty
          >

            <template #cell(index)="data">
              {{ tableVisitationLogSettings.currentPage * tableVisitationLogSettings.perPage - tableVisitationLogSettings.perPage + (data.index + 1) }}
            </template>

            <template #cell()="data">
              <div class="text-nowrap">
                {{ data.value }}
              </div>
            </template>

            <template #cell(actions)="row">
              <div class="text-nowrap">
                <b-button
                  size="sm"
                  @click="onViewVisitationLog(row.item)"
                >
                  View
                </b-button>
              </div>
            </template>

          </b-table>

          <b-row>
            <b-col
              cols="12"
              sm="6"
            >
              <div class="w-100 w-sm-25 mb-2 sm-mb-2">
                <b-select
                  v-model="tableVisitationLogSettings.perPage"
                  :options="tableVisitationLogSettings.pageOptions"
                  size="sm"
                />
              </div>
            </b-col>
            <b-col
              cols="12"
              sm="6"
              class="d-flex justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="tableVisitationLogSettings.currentPage"
                :total-rows="tableVisitationLogSettings.totalRows"
                :per-page="tableVisitationLogSettings.perPage"
                first-number
                last-number
                pills
                prev-text="Prev"
                next-text="Next"
                aria-controls="table"
              />
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>

      <template #modal-footer="{ cancel }">
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-view-visitation-request-histories"
      title="View Details"
      size="lg"
      scrollable
      no-close-on-backdrop
    >
      <b-row>
        <b-col
          cols="12"
        >
          <b-form-group>
            <label>Visited At</label>
            <b-input
              :value="dateTimeFormatter(selected.log?.created_at)"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-group>
            <label>LGA/RFO</label>
            <b-input
              :value="selected.log?.user_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-group>
            <label>Purpose</label>
            <b-input
              :value="selected.log?.purpose_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group>
            <label>Remarks</label>
            <b-textarea
              :value="selected.log?.remarks"
              rows="10"
              max-rows="10"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <p class="mb-1">
            Uploaded Photos
          </p>
          <b-table
            hover
            small
            striped
            responsive
            show-empty
            :fields="tableVisitationLogAttachments.fields"
            :items="selected.log?.visitation_log_attachments"
          >
            <template #cell(index)="row">
              {{ row.index + 1 }}
            </template>
            <template #cell(action)="row">
              <div class="text-nowrap">
                <b-button
                  size="sm"
                  class="mr-1"
                  @click="onPreviewVisitationLogAttachment(row.item)"
                >
                  Preview
                </b-button>
              </div>
            </template>
          </b-table>
        </b-col>

      </b-row>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="moda-cancel-request"
      title="Cancel Request"
      scrollable
      no-close-on-backdrop
      @ok="onConfirmAction"
    >
      <ValidationObserver ref="form">
        <form
          role="form"
          novalidate
        >
          <b-row>
            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="remarks"
                vid="remarks"
                rules="required|max:1000"
              >
                <div class="form-group">
                  <label for="remarks">Remarks</label>
                  <b-textarea
                    id="remarks"
                    v-model="cancelForm.remarks"
                    :state="errors.length > 0 ? false : null"
                    rows="10"
                    max-rows="10"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </div>
              </ValidationProvider>
            </b-col>

          </b-row>
        </form>
      </ValidationObserver>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          class="mr-1"
          :disabled="state.busy"
          @click="ok()"
        >
          Proceed
        </b-button>

        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-preview-log-attachment"
      :title="ModalAttachmentTitle"
      size="xl"
      scrollable
      no-close-on-backdrop
    >
      <b-img
        :src="visitationLogAttachment.preview_photo_path"
        fluid-grow
      />
      <template #modal-footer="{ cancel }">
        <b-button
          size="sm"
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { AxiosError, AgentReportService, SharedLogService } from '@/services'
import formatter from '@/mixins/formatter'
import url from '@/mixins/url'

export default {
  name: 'AgentVisitRequestHistories',

  middleware: ['auth', 'agent'],

  mixins: [formatter, url],

  metaInfo () {
    return {
      title: 'Reports - Visit Request Histories'
    }
  },

  data () {
    return {
      state: {
        busy: false
      },
      filter: {
        date_from: this.dateFormatter(Date.now()),
        date_to: this.dateFormatter(Date.now()),
        filter_status: 'All',
        opportunity_only: 0
      },
      selected: {
        visit_request: null,
        log: null
      },
      list: {
        purposes: []
      },
      options: {
        filter_statuses: [
          { text: 'All', value: 'All' },
          { text: 'Closed', value: 'Closed' },
          { text: 'Cancelled', value: 'Cancelled' }
        ]
      },
      cancelForm: {
        id: 0,
        remarks: ''
      },
      visitationLogAttachment: {
        preview_photo_name: null,
        preview_photo_path: null
      },
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'status', sortable: true },
          { key: 'ticket_number' },
          { key: 'ticket_category_name', label: 'ticket category' },
          { key: 'due_date' },
          { key: 'opportunity' },
          { key: 'booked' },
          { key: 'customer.first_name', label: 'first name' },
          { key: 'customer.last_name', label: 'last name' },
          { key: 'customer.mobile_number', label: 'mobile number' },
          { key: 'customer.province.province_name', label: 'province' },
          { key: 'customer.municipality.municipality_name', label: 'municipality' },
          { key: 'customer.barangay.barangay_name', label: 'barangay' },
          { key: 'lead_name', label: 'assigned to' },
          { key: 'agent_name', label: 'requested by' },
          { key: 'closed_at', label: 'closed at' },
          { key: 'cancelled_at', label: 'cancelled at' },
          { key: 'updated_at', formatter: this.dateTimeFormatter, sortable: true }
        ]
      },
      tableVisitationLogSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', label: 'visitation date', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'user_name', label: 'fso' },
          { key: 'purpose_name', label: 'purpose' }
        ]
      },
      tableVisitationLogAttachments: {
        fields: [
          { key: 'index', class: 'text-center' },
          { key: 'action', class: 'text-center' },
          { key: 'file_original_name', label: 'file name' }
        ]
      }
    }
  },

  computed: {
    ModalAttachmentTitle () {
      return this.visitationLogAttachment.preview_photo_name
    }
  },

  mounted () {
    core.index()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await AgentReportService.getVisitRequestHistories(
          this.objectToUrl({
            page: ctx.currentPage,
            per_page: ctx.perPage,
            sort: ctx.sortBy,
            sort_desc: ctx.sortDesc,
            filter_text: ctx.filter,
            filter_from: this.filter.date_from,
            filter_to: this.filter.date_to,
            filter_status: this.filter.filter_status,
            opportunity_only: this.filter.opportunity_only
          })
        )
        this.tableSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    async myVisitationLogProvider (ctx) {
      try {
        const { data } = await SharedLogService.getVisitationLogs(
          this.objectToUrl({
            page: ctx.currentPage,
            per_page: ctx.perPage,
            sort: ctx.sortBy,
            sort_desc: ctx.sortDesc,
            filter_text: ctx.filter,
            customer_id: this.selected.visit_request.customer?.id,
            request_id: this.selected.visit_request.id
          })
        )
        this.tableVisitationLogSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    onShowDetailForm (current) {
      this.selected.visit_request = { ...current }
      this.$bvModal.show('modal-view-detail')
    },

    async onConfirmAction (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.form.validate().then(async success => {
        if (success) {
          const title = 'Do you really want cancel request?'
          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: title,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              return this.onFormPutSubmit()
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            centered: true
          })
        }
      })
    },

    async onFormPutSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        AgentReportService.put(this.cancelForm).then(({ data }) => {
          this.state.busy = false
          this.$bvModal.hide('moda-cancel-request')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.$refs.table.refresh()
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
                title: 'Validation Error',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                centered: true
              })
              this.$refs.form.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    },

    onViewVisitationLog (current) {
      this.selected.log = { ...current }
      this.$bvModal.show('modal-view-visitation-request-histories')
    },

    onPreviewVisitationLogAttachment (attachment) {
      this.visitationLogAttachment.preview_photo_name = attachment.file_original_name
      this.visitationLogAttachment.preview_photo_path = this.getImageUrl(`${this.selected.log?.attachment_path}/${attachment.file_hash_name}`)
      this.$bvModal.show('modal-preview-log-attachment')
    },

    onFilterChanged () {
      this.$refs.table.refresh()
    }
  }
}
</script>
<style>
#modal-preview-log-attachment .modal-xl {
  width:100vw;
  max-width: none;
  min-height: 100vh;
  margin: 0
}
#modal-preview-log-attachment .modal-content {
  min-height: 100vh;
}
</style>
